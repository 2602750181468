import React from 'react'

const GoogleMap = () => {
    return (
        <div className="google-map-area section text-center section-padding-bottom">
            <div className="container">
                <div className="contact-map-area" data-aos="fade-up">
                <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d429.8801716808055!2d-97.7933124867405!3d30.46326620561846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865b32b121c64e61%3A0x798d244adcc97a21!2s13785%20Research%20Blvd%2C%20Austin%2C%20TX%2078750!5e0!3m2!1sen!2sus!4v1664898719904!5m2!1sen!2sus" aria-hidden="false"></iframe>
                </div>
            </div>
        </div>
    )
}

export default GoogleMap;
