import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';

const ContactFrom = () => {
    const { register, errors } = useForm({
        mode: "onBlur"
    });
    const [formData, setFormData] = useState({
        from_name: '',
        from_email: '',
        message: '',
        subject: ''
    });
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("logs from contact us form", e)
        try {
            // email template name 'Contactus template'
            await emailjs.send('service_0sswgs8', 'template_ioroxvj', formData, 'i-8X0198e36XAW_oT');

            // Reset the form after successful submission
            setFormData({ from_name: '', from_email: '', message: '', subject: '' });  // Corrected keys
            alert('Email sent successfully!');
        } catch (error) {
            alert('Oops something went wrong');
            console.error('Error sending email:', error);
        }
    }
    return (
        <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
            <form action="" method="POST" onSubmit={handleSubmit}>
                <div className="row mb-n6">
                    <div className="col-md-6 col-12 mb-6">
                        <input type="text" placeholder="Your Name *" name="from_name" value={formData.from_name}
                               onChange={handleChange} ref={register({ required: 'Name is required' })} />
                        {errors.name && <p>{errors.name.message}</p>}
                    </div>
                    <div className="col-md-6 col-12 mb-6">
                        <input type="email" placeholder="Email *" name="from_email" value={formData.from_email}
                               onChange={handleChange} ref={register({
                            required: 'Email is required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "invalid email address"
                            }
                        })} />
                        {errors.email && <p>{errors.email.message}</p>}
                    </div>
                    <div className="col-md-12 col-12 mb-6">
                        <input type="text" placeholder="Subject *" name="subject" value={formData.subject} onChange={handleChange}
                               ref={register({ required: 'Subject is required' })} />
                        {errors.subject && <p>{errors.subject.message}</p>}
                    </div>
                    <div className="col-12 mb-6">
                        <textarea name="message" value={formData.message} onChange={handleChange}
                                  placeholder="Message" ref={register({ required: 'Message is required' })}></textarea>
                        {errors.message && <p>{errors.message.message}</p>}
                    </div>
                    <div className="col-12 text-center mb-6">
                        <button type="submit" className="btn btn-brand-color btn-hover-secondary">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ContactFrom;
