import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import WorkContainer from '../container/Work/WorkContainer';
import CallToAction from '../container/CallToAction/CallToAction';
import AboutFive from '../container/About/AboutFive';
import BrandContainer from '../container/Brand/BrandContainer';
import Faq from '../container/Faq/Faq';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import aboutFiveData from '../data/content/aboutFiveContent';
import RecruitmentContainerTwo from "../container/Work/RecruitmentContainerTwo";
import CallToActionThree from "../container/CallToAction/CallToActionThree";
import {useLocation} from "react-router-dom";
import data3 from "../data/SEO/seoData";

const RecruitAndStaffService = () => {
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    console.log(currentPage, currentPage.pathname);
    console.log(seo_data,"seo_data");
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} description={seo_data[0].metaDesc} keywords={seo_data.keywords} />
            <Header />
            <Breadcrumb
                image={seo_data[0].bcEleImage}
                title={seo_data[0].bcEleTitle}
                content="Home"
                contentTwo="Industries"
            />
            <RecruitmentContainerTwo
                title="IT and non-IT Recruitment and Staffing Services"
                sectionTitle="Hiring the right talent is our forte. At Tetrahed, Inc., we are focused mainly on recruiting the best talent, no matter what industry you are looking for."
            />
            {/*<CallToAction />
            <AboutFive
                mainContent={aboutFiveData.aboutUs.mainContent}
                subTitle={aboutFiveData.aboutUs.subTitle}
                title={aboutFiveData.aboutUs.title}
            />
            <BrandContainer classOption="section-padding-bottom" />
            <Faq />*/}
            <CallToActionThree titleContent="Transform the way you find and retain top talent – Start now!"/>
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default RecruitAndStaffService;