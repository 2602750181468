import React, {useEffect, useState} from 'react'
import SEO from '../components/SEO';
import PropTypes from "prop-types";
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import BlogClassicData from '../data/blog/BlogClassic.json';
import BlogDetailsContainer from '../container/BlogGrid/BlogDetailsContainer';
import CallToAction from '../container/CallToAction/CallToAction';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import axios from "axios";

const BlogDetails = ({match: {params: {id}}}) => {
    // const blogId = parseInt(id, 10)
    console.log("blog details page", id);
    const baseUri = "https://tetrahed.s3.us-east-1.amazonaws.com/BlogClassic.json";
    const fetchDataFromAPI = async () => {
        try {
            const response = await axios.get(baseUri);
            if (response.status === 200) {
                console.log(response)
                setFetchedData(response.data);
            } else {
                throw new Error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
            // setting internal JobsData
            setFetchedData(BlogClassicData);
        }
    };
    const [fetchedData, setFetchedData] = useState([]);
    useEffect(() => {
        fetchDataFromAPI().then();
    }, []);

    const data = fetchedData ? fetchedData.find(blog => blog.seoUrl === id) : null;
    console.log("data on load blog details", data)
    return (
        <React.Fragment>
            {data ? (
                <>
                    <SEO title={data.metaTitle} description={data.metaDescription} />
                    <Header bgStyle={{background: '#030e22'}} />
                    {/*<Breadcrumb
                    image="images/bg/breadcrumb-bg-four.jpg"
                    title={data.title}
                    content="Home"
                    contentTwo="Blog Classic"
                />*/}
                    <BlogDetailsContainer data={data} />
                    <CallToAction sectionTitleContent="" />
                    <Footer />
                    <ScrollToTop />
                </>
            ) : (
                // Handle case when data is not available
                <div className="loaderMainDiv">
                    <span className="loader"></span>
                </div>
            )}
        </React.Fragment>
    )
}

BlogDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([    
                PropTypes.string,
                PropTypes.number
            ])
        })
    })
}

export default BlogDetails;
