import React, {useState, useEffect, useRef} from "react";
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import {Link} from "react-router-dom";
import SectionTitle from "../../components/SectionTitles/SectionTitle";

const AboutFour = ({title, subTitle, mainContent, mainContent2, backgroundImg, foregroundImg, toShowBtn}) => {
    const [didViewCountUp, setDidViewCountUp] = useState(false);
    const onVisibilityChange = isVisible => {
        if (isVisible) {
        setDidViewCountUp(true);
        }
    };
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top about-section-padding-bottom-200">
            <div className="container">
                <div className="row">

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                            {/*<SectionTitleTwo
                                subTitle="Innovative Approach for Every Business Need"
                                title="Smart Solutions and Lasting Impact"
                            />*/}
                            <SectionTitle showWithSpan="true"
                                subTitle={subTitle}
                                title={title}
                            />

                            <div className="row row-cols-sm-2 row-cols-auto mb-n6">
                                <p className="text-justify w-100" style={{maxWidth: "600px"}}>{mainContent}</p>
                                <p className="text-justify w-100 mt-3" style={{maxWidth: "600px"}}>{mainContent2}</p>
                            </div>

                        </div>
                        {
                            toShowBtn == "true"  ? (
                                <div>
                                    <Link className="btn btn-primary btn-hover-secondary customBtn2" to={process.env.PUBLIC_URL + "/contact-us"} data-aos="fade-up" data-aos-delay="300">Contact Us</Link>
                                </div>
                            ) : (<div></div>)
                        }
                    </div>

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-image-area about-shape-animation right-0 mr-0">
                            <div className="about-image js-tilt">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={`${foregroundImg}`} alt="foregroundImg" />
                                </Tilt>
                            </div>
                            <div className="about-image js-tilt">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={`${backgroundImg}`} alt="backgroundImg" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/video-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default AboutFour;
