import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import ContactFrom from '../../components/ContactFrom/ContactFrom.jsx';
import ContactFormNew from "../../components/ContactFrom/ContactFormNew";

const ContactFromContainer = () => {
    return (
        <div className="contact-form-section section section-padding-t90-b100 bg-primary-blue">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-2 col-lg-8">
                        <SectionTitle
                            headingOption="fz-32"
                            title="Enquire Now"
                            subTitle="Feel free to submit your queries using the form, and we'll be in touch soon."
                        />
                        <ContactFrom />
                        {/*<ContactFormNew />*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactFromContainer
