import React, {Fragment, useState} from 'react';
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

const ProjectForm = () => {
    const { register, errors } = useForm({
        mode: "onBlur"
    });
    // const onSubmit = data => console.log(data);
    const [formData, setFormData] = useState({
        from_name: '',
        from_email: '',
        message: ''
    });
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("logs from contact us form", e)
        try {
            // Use emailjs to send email
            // await emailjs.send('service_gmail', 'template_example', formData, 'user_example');
            await emailjs.send('service_0sswgs8', 'template_tj0zu4g', formData, 'i-8X0198e36XAW_oT');

            // Reset the form after successful submission
            setFormData({ from_name: '', from_email: '', message: '' });
            alert('Email sent successfully!');
        } catch (error) {
            alert('Oops something went wrong');
            console.error('Error sending email:', error);
        }
    }
    
    return (
        <Fragment>
        <form onSubmit={handleSubmit}>
            <div className="row mb-n4">
                <div className="col-md-12 col-12 mb-4">
                    <input type="text" placeholder="Your Name *" name="from_name" value={formData.from_name}
                           onChange={handleChange} ref={register({ required: 'Name is required' })} />
                    {errors.name && <p>{errors.name.message}</p>}
                </div>
                <div className="col-md-12 col-12 mb-4">
                    <input type="email" placeholder="Email *" name="from_email" value={formData.from_email}
                           onChange={handleChange} ref={register({
						required: 'Email is required',
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
							message: "invalid email address"
						}
					})} />
                    {errors.email && <p>{errors.email.message}</p>}
                </div>
                <div className="col-12 mb-6">
                    <textarea name="message" value={formData.message} onChange={handleChange}
                              placeholder="Message" ref={register({ required: 'Message is required' })}></textarea>
                    {errors.message && <p>{errors.message.message}</p>}
                </div>
                <div className="col-12 text-center mb-4">
                    <button className="btn btn-brand-color btn-hover-secondary">Get a free consultation</button>
                </div>
            </div>
        </form>
        <p className="form-messege"></p>
        </Fragment>
    )
}

export default ProjectForm;
