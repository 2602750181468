import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import {Alert, Spinner} from "reactstrap";

const JobApplicationForm = ({data}) => {
    const { register, errors } = useForm({
        mode: "onBlur"
    });
    let skipFileConvertIfNull;
    const isAadharInputRequired = data.Country !== 'US';
    console.log("isAadharInputRequired>>>>>>>>>>>", isAadharInputRequired);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        Email: '',
        Mobile: '',
        Address: '',
        Resume: null,
        Aadhar: null,
        ExpectedSalary: '',
        CurrentSalary: ''
    });
    /*let state = {
        file: null,
        base64URL: ""
    };*/

    const handleChange = (e) => {
        // For file inputs, handle differently
        console.log("event logged here----->",e.target.files)
        if (e.target.type === 'file') {
            setFormData({ ...formData, [e.target.name]: e.target.files[0] });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (!file) {
                reject(new Error('No file provided'));
                return;
            }
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const resetForm = () => {
        // Reset the form after successful submission
        setFormData({
            firstName: '',
            lastName: '',
            Email: '',
            Mobile: '',
            Address: '',
            Resume: null,
            Aadhar: null,
            ExpectedSalary: '',
            CurrentSalary: ''
        });
        clearFileInput('fileInput', 'Resume');
        if(isAadharInputRequired) clearFileInput('fileInput1', 'Aadhar');
    }

    const generateHtmlTable = (formData) => {
        let tableHtml = "<table style='font-family: 'Google Sans' '>";
        tableHtml += '<thead><tr><td colspan="2" style="font-size: 16px; border-bottom: 1px solid #000;">Job Application</td></tr></thead>';
        tableHtml += '<tbody>';
        tableHtml += `<tr><td><b>First Name:</b></td><td>${formData.firstName}</td></tr>`;
        tableHtml += `<tr><td><b>Last Name:</b></td><td>${formData.lastName}</td></tr>`;
        tableHtml += `<tr><td><b>Email:</b></td><td>${formData.Email}</td></tr>`;
        tableHtml += `<tr><td><b>Mobile:</b></td><td>${formData.Mobile}</td></tr>`;
        tableHtml += `<tr><td><b>Address:</b></td><td>${formData.Address}</td></tr>`;
        tableHtml += `<tr><td><b>Current Salary:</b></td><td>${formData.CurrentSalary}</td></tr>`;
        tableHtml += `<tr><td><b>Expected Salary:</b></td><td>${formData.ExpectedSalary}</td></tr>`;
        tableHtml += '</tbody></table>';
        return tableHtml;
    };

    const clearFileInput = (id, name) => {
        const oldFileInput = document.getElementById(id);

        // Create a new input element
        const newFileInput = document.createElement('input');
        newFileInput.type = 'file';
        newFileInput.id = id;
        newFileInput.name = name;

        // Replace the old input element with the new one
        oldFileInput.parentNode.replaceChild(newFileInput, oldFileInput);
    };



    const checkValidation = () => {
        // Check if any required field is empty
        /*console.log('Object.values(formData)', Object.values(formData));*/
        // let inputFields = document.getAttribute('requiredField');
        // console.log("inputFields>>>>>>", inputFields);
        if (Object.values(formData).some(value => value === '') && Object.values(formData).some(value => value == null)) {
            setErrorMessage('Please fill out all required fields');
            setLoading(false);
            return false;
        }

        // Check if any file inputs are null or empty
        /*const fileInputs = isAadharInputRequired? ['Resume', 'Aadhar'] : ['Resume'];*/
        skipFileConvertIfNull = (formData['Aadhar']);
        console.log('skipFileConvertIfNull', skipFileConvertIfNull);
        const fileInputs = ['Resume'];
        for (const key of fileInputs) {
            if (!formData[key]) {
                setErrorMessage(`Please select ${key}`);
                setLoading(false);
                return false;
            }
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let base64Resume,
            base64Aadhar;
        let attachments = [];
        setLoading(true);
        if(!checkValidation()){
            return;
        }
        console.log("logs from contact us form", formData, skipFileConvertIfNull);
        base64Resume = await getBase64(formData.Resume);
        if(isAadharInputRequired && skipFileConvertIfNull){
            base64Aadhar = await getBase64(formData.Aadhar);
        }

        // Push resume attachment
        attachments.push({
            name: formData.Resume.name,
            data: base64Resume
        });

        // Push Aadhar attachment if required
        if (isAadharInputRequired && skipFileConvertIfNull) {
            attachments.push({
                name: formData.Aadhar.name,
                data: base64Aadhar
            });
        }

        const config = {
            SecureToken : "118c3d49-ce2a-4e28-998f-cb1a17538acd",
            To : 'careers@tetrahed.com',
            From : "careers@tetrahed.com",
            Subject : `Job application for ${data.Role}`,
            Body : generateHtmlTable(formData),
            Attachments : attachments
        }
        try {
            window.Email.send(config).then(
                (message) => {
                    if(message == 'OK'){
                        resetForm();
                        setFormSubmitted(true);
                    }
                    else{
                        // setErrorMessage(message || 'Oops something went wrong, please try after sometime');
                        setErrorMessage('Oops something went wrong, please try after sometime');
                        setLoading(false);
                    }
                }
            );
        } catch (error) {
            setErrorMessage(error.message || 'Oops something went wrong');
            console.error('Error sending email:', error);
        }
    };

    return (
        <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
            {
                formSubmitted ? (
                    <div className="successDiv text-center">
                        <img className="pulse" width="96" height="96"
                             src="https://img.icons8.com/color/96/verified-account--v1.png"
                             alt="verified-account--v1"/>
                        <h5 style={{padding: '24px'}}>
                            Your application is submitted! <br/> We will get in touch soon!
                        </h5>
                    </div>
                ) : (
                    <div className="InputFieldSection mt-4">
                        <form onSubmit={handleSubmit}>
                            <section className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                    <label htmlFor="">First Name<span className="text-danger">*</span></label>
                                    <input type="text" name="firstName" value={formData.firstName} maxLength="40" requiredField="true"
                                           onChange={handleChange} ref={register({ required: 'First Name is required' })} />
                                    <small className="text-danger">
                                        {errors.firstName && <p>{errors.firstName.message}</p>}
                                    </small>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                    <label htmlFor="">Last Name<span className="text-danger">*</span></label>
                                    <input type="text" name="lastName" value={formData.lastName} onChange={handleChange}
                                           requiredField="true"
                                           maxLength="40" ref={register({ required: 'Last Name is required' })} />
                                    <small className="text-danger">
                                        {errors.lastName && <p>{errors.lastName.message}</p>}
                                    </small>
                                </div>

                            </section>
                            <section className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                    <label htmlFor="">Mobile Number<span className="text-danger">*</span></label>
                                    <input type="tel" name="Mobile" value={formData.Mobile} requiredField="true"
                                           onChange={handleChange} ref={register({ required: 'Mobile Number is required' })}
                                           onkeydown="return false" onwheel="return false" maxLength="12" minLength="10"
                                    />
                                    <small className="text-danger">
                                        {errors.Mobile && <p>{errors.Mobile.message}</p>}
                                    </small>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                    <label htmlFor="">Email<span className="text-danger">*</span></label>
                                    <input type="email" name="Email" value={formData.Email} requiredField="true"
                                           onChange={handleChange} ref={register({
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address"
                                        }
                                    })} />
                                    <small className="text-danger">
                                        {errors.Email && <p>{errors.Email.message}</p>}
                                    </small>
                                </div>
                            </section>
                            {/* Salary Details */}
                            <section className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                    <label htmlFor="">Current Salary <small className="text-muted">(In LPA)</small></label>
                                    <input type="text" name="CurrentSalary" value={formData.CurrentSalary} maxLength="10"
                                           onChange={handleChange} />
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                    <label htmlFor="">Expected Salary <small className="text-muted">(In LPA)</small></label>
                                    <input type="text" name="ExpectedSalary" value={formData.ExpectedSalary}
                                           onChange={handleChange} />
                                </div>
                            </section>
                            <section className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <label htmlFor="">Address<span className="text-danger">*</span></label>
                                    <input type="text" name="Address" value={formData.Address} maxLength="150" requiredField="true"
                                           onChange={handleChange} ref={register({ required: 'Address is required' })} />
                                    <small className="text-danger">
                                        {errors.Address && <p>{errors.Address.message}</p>}
                                    </small>
                                </div>
                            </section>
                            {/* Attachments section */}
                            <section className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 mb-8">
                                    <label htmlFor="">Resume<span className="text-danger">*</span>
                                        <div style={{borderRadius: '6px', padding: '12px', background: 'whitesmoke', border: '2px dashed'}}>
                                            <input type="file" id="fileInput" name="Resume" accept=".doc, .docx" onChange={handleChange} />
                                            {/*<small className="text-muted">Note: Accepted format .docs/.doc</small>*/}
                                        </div>
                                    </label>
                                </div>
                                {
                                    isAadharInputRequired &&
                                    <div className="col-lg-6 col-md-12 col-sm-12 mb-8">
                                        <label htmlFor="">Aadhar
                                            {/*<span className="text-danger">*</span>*/}
                                            <div style={{borderRadius: '6px', padding: '12px', background: 'whitesmoke', border: '2px dashed'}}>
                                                <input type="file" id="fileInput1" name="Aadhar" onChange={handleChange} />
                                            </div>
                                        </label>
                                    </div>
                                }
                            </section>

                            <div id="globalErrStrip">
                                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                            </div>
                            <div className="col-sm-12 col-xs-12 text-right mb-4">
                                <button type="submit" disabled={loading} className="btn btn-brand-color btn-hover-secondary">
                                    {/*{loading ? 'Loading...' : 'Apply'}*/}
                                    {loading ? <Spinner color="light">Loading...</Spinner> : 'Apply'}
                                </button>
                            </div>
                        </form>
                    </div>
                )
            }
        </div>
    )
}


export default JobApplicationForm;
