import React from 'react';
import Accordion, { AccordionItem, AccordionTitle, AccordionContent } from "../Accordion";

const AccordionWrap = () => {
    return (
        <div className="agency-accordion max-mb-n30">
            <Accordion>
                <AccordionItem id="one">
                    <AccordionTitle id="one">What IT services does Tetrahed, Inc provide?</AccordionTitle>
                    <AccordionContent id="one">Tetrahed, Inc. offers a comprehensive range of IT services, including software development, cloud computing, cybersecurity, network services, data analytics, AI/ML, identity access management and business consulting.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="two">
                    <AccordionTitle id="two">What technologies and platforms does Tetrahed, Inc. have expertise in?</AccordionTitle>
                    <AccordionContent id="two">Tetrahed, Inc. has expertise in a diverse range of technologies and platforms, including but not limited to Java, .NET, Python, React JS, SQL, MongoDB, AWS, Azure, GCP and more.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="three">
                    <AccordionTitle id="three">What sets Tetrahed, Inc. apart from other IT service providers?</AccordionTitle>
                    <AccordionContent id="three">Tetrahed, Inc. stands out for its client-centric approach, innovative solutions, experienced team, and commitment to delivering high-quality results within deadlines.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="four">
                    <AccordionTitle id="four">What is Tetrahed, Inc.'s approach to data security and privacy?</AccordionTitle>
                    <AccordionContent id="four">Tetrahed, Inc prioritizes data security and privacy, implementing robust measures to safeguard client information and ensuring compliance with relevant regulations.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="five">
                    <AccordionTitle id="five">Can Tetrahed, Inc. assist with remote staffing solutions?</AccordionTitle>
                    <AccordionContent id="five">Yes, Tetrahed, Inc. has experience in providing remote staffing solutions, ensuring access to top talent globally while maintaining effective communication and collaboration.</AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default AccordionWrap;
