import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import ServiceIconBoxTwo from '../container/service/ServiceIconBoxTwo';
import ServiceSkill from '../container/service/ServiceSkill';
import AboutSix from '../container/About/AboutSix';
import FunfactTwo from '../container/Funfact/FunfactTwo';
import ContactInformationThree from '../container/ContactInformation/ContactInformationThree';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import data3 from "../data/SEO/seoData";
import {useLocation} from "react-router-dom";


const Service = () => {
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    console.log(currentPage, currentPage.pathname);
    console.log(seo_data,"seo_data");
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} description={seo_data[0].metaDesc} />
            <Header />
            <Breadcrumb
                image={seo_data[0].bcEleImage}
                title={seo_data[0].bcEleTitle}
                content="Home"
                contentTwo="Services"
            />
            <ServiceIconBoxTwo />
            <ServiceSkill />
            <AboutSix />
            <FunfactTwo />
            <ContactInformationThree />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Service;



