import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import WorkContainer from '../container/Work/WorkContainer';
import CallToActionThree from '../container/CallToAction/CallToActionThree';
import AboutFive from '../container/About/AboutFive';
import BrandContainer from '../container/Brand/BrandContainer';
import Faq from '../container/Faq/Faq';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import data2 from "../data/content/aboutFiveContent";
import CallToActionTwo from "../container/CallToAction/CallToActionTwo";
import {useLocation} from "react-router-dom";
import data3 from "../data/SEO/seoData";

const Industries = () => {
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} description={seo_data[0].metaDesc} />
            <Header />
            <Breadcrumb
                image={seo_data[0].bcEleImage}
                title={seo_data[0].bcEleTitle}
                content="Home"
                contentTwo="Industries"
            />
            <WorkContainer />
            {/*<CallToAction />
            <AboutFive
                mainContent={data2.aboutUs.mainContent}
                subTitle={data2.aboutUs.subTitle}
                title={data2.aboutUs.title}
            />
            <BrandContainer classOption="section-padding-bottom" />
            <Faq />*/}
            <CallToActionThree titleContent="Take Your Business to the Next Level" />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Industries;