import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";


const WorkDetailsContainer = ({ data }) => {
    const cate = data.categories.map((value, i) => {
        return(
            <span className="d-inline" key={i}>{value}{i !== data.categories.length - 1 && " , "}</span>
        )
    })
    return (
        <div className="section section-padding pt-5">
            <div className="container">
                <div className="row pt--100 pb--80">

                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="work-left work-details" data-aos="fade-up">
                            <div className="portfolio-main-info">
                               
                            <div className="custom-column-thumbnail mt-lg-14 mt-1" data-aos="fade-up">
                                <img className="w-100" src={`https://tetrahed.s3.us-east-1.amazonaws.com/${data.gallery.imageTwo}`} alt="Agency" />
                            </div>
                                <div className="work-share pt--70 pt_md--40 pt_sm--40">
                                    <h6 className="heading heading-h6"></h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 col-md-6 offset-lg-1 col-12">
                        <div className="work-left work-details mt-6">
                            <div className="work-main-info">
                                <div className="work-content">
                                    <h6 className="title" data-aos="fade-up">{data.bodytitle}</h6>

                                    <div className="desc mt-8">
                                        {data.body.map((value, i) => {
                                            return(
                                                <div key={i} className="content mb-5" data-aos="fade-up" dangerouslySetInnerHTML={{__html: value}} />
                                            )
                                        })}

                                        {/*<div className="work-btn">
                                            <Link className="btn btn-primary btn-hover-secondary" to={process.env.PUBLIC_URL + data.btn.link}>{data.btn.text}</Link>
                                        </div>*/}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="custom-column-thumbnail mt-lg-14 mt-1" data-aos="fade-up">
                            <img className="w-100" src={`${process.env.PUBLIC_URL}/${data.largeImage}`} alt="Agency" />
                        </div>
                    </div>
                </div>

                <div className="row mt-lg-20 mt-12">
                    <div className="col-lg-4 col-md-12 col-12">
                            <div className="thumbnail" data-aos="fade-up">
                                <img className="w-100" src={`https://tetrahed.s3.us-east-1.amazonaws.com/${data.gallery.imageThree}`} alt="Agency" />
                            </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                        <div className="data-security mt-lg-0 mt-6" data-aos="fade-up">
                            <h6 className="heading heading-h3">{data.subContent.title}</h6>

                            <div className="inner">
                                <p>{data.subContent.text}</p>
                                <p>{data.subContent.text1}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

WorkDetailsContainer.propTypes = {
    data: PropTypes.object
};

export default WorkDetailsContainer;
