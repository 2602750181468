import {useState, useEffect, useRef} from "react";
import { Progress } from 'reactstrap';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';
import SectionTitle from "../../components/SectionTitles/SectionTitle";


const ServiceSkill = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top skill-section-padding-bottom bg-primary-blue" data-bg-color="#f8faff">
            <div className="container">
                <div className="row">

                    <div className="col-xl-5 col-lg-6 order-lg-1 order-2" data-aos="fade-up">

                        <div className="progressbar-area mt-lg-0 mt-md-50 mt-sm-50 mt-40">

                            <SectionTitle showWithSpan="true"
                                subTitle="Business Expertise with Technology Services"
                                title="Offering Unique Business Transformation Initiatives and Strategic Vision"
                            />

                            <div className="progress-bar--one">
                                <div className="progress-charts">
                                    <h6 className="heading ">Software Development</h6>
                                    <div className="single-progress">
                                        <Progress data-aos="fade-right" data-aos-delay="100" data-aos-duration="450" barClassName="gradient-1"  value="89" >
                                            <span className="percent-label">89%</span>
                                        </Progress>
                                    </div>
                                </div>

                                <div className="progress-charts ">
                                    <h6 className="heading ">Access Management</h6>
                                    <div className="single-progress">
                                        <Progress data-aos="fade-right" data-aos-delay="200" data-aos-duration="450" barClassName="gradient-2"  value="81" >
                                            <span className="percent-label">81%</span>
                                        </Progress>
                                    </div>
                                </div>

                                <div className="progress-charts ">
                                    <h6 className="heading ">Cloud Security</h6>
                                    <div className="single-progress">
                                        <Progress data-aos="fade-right" data-aos-delay="300" data-aos-duration="450" barClassName="gradient-3"  value="79" >
                                            <span className="percent-label">79%</span>
                                        </Progress>
                                    </div>
                                </div>

                                <div className="progress-charts ">
                                    <h6 className="heading ">Data Analytics</h6>
                                    <div className="single-progress">
                                        <Progress data-aos="fade-right" data-aos-delay="400" data-aos-duration="450" barClassName="gradient-4" value="75" >
                                            <span className="percent-label">75%</span>
                                        </Progress>
                                    </div>
                                </div>
                            </div>

                            {/*<div>
                                Our diverse IT team brings deep industry expertise in technologies including Oracle, Salesforce,
                                AWS, Google Cloud, SAP, Microsoft Azure, IBM, VMware, and more, ensuring innovative solutions
                                tailored to meet our clients' diverse needs. We stay up-to-date on the evolving trends and
                                technologies to help our clients navigate challenges in the changing IT landscape.
                            </div>*/}

                        </div>

                    </div>

                    <div className="offset-xl-1 col-xl-6 col-lg-6 order-lg-2 order-1" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-image-area right-0 skill-image-area">
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src="https://tetrahed.s3.us-east-1.amazonaws.com/Service-foreground-1.jpg" alt="" />
                                </Tilt>
                            </div>
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src="https://tetrahed.s3.us-east-1.amazonaws.com/Service-Background-1.jpg" height="400px" alt="" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/video-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ServiceSkill;
