import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import AboutFour from '../container/About/AboutFour';
import Video from '../container/Video/Video';
import AboutFive from '../container/About/AboutFive';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import data1 from "../data/content/aboutFourContent";
import data2 from "../data/content/aboutFiveContent";
import CallToActionThree from "../container/CallToAction/CallToActionThree";
import {useLocation} from "react-router-dom";
import data3 from "../data/SEO/seoData";


const StaffAugmentation = () => {
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} description={seo_data[0].metaDesc} keywords={seo_data.keywords} />
            <Header />
            <Breadcrumb
                image={seo_data[0].bcEleImage}
                title={seo_data[0].bcEleTitle}
                content="Home"
                contentTwo="StaffAugmentation"
                contentThree="StaffAugmentation"
            />
            <AboutFour
                mainContent={data1.staffAugmentation.mainContent} backgroundImg={data1.staffAugmentation.backgroundImg}
                subTitle={data1.staffAugmentation.subTitle} foregroundImg={data1.staffAugmentation.foregroundImg}
                title={data1.staffAugmentation.title} toShowBtn="false"
            />
            <Video content="Augmenting Talents for Continuous Business Growth" />
            <AboutFive
                mainContent={data2.staffAugmentation.mainContent} foregroundImg={data2.staffAugmentation.foregroundImg}
                subTitle={data2.staffAugmentation.subTitle} backgroundImg={data2.staffAugmentation.backgroundImg}
                title={data2.staffAugmentation.title}
            />
            {/*<TestimonialContainer classOption="bg-primary-blue" />*/}
            <CallToActionThree titleContent="Effortlessly Fill the Skill Gaps and Meet Project Deadlines On-Time" />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default StaffAugmentation;



