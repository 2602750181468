import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import ContactInformation from '../container/ContactInformation/ContactInformation';
import GoogleMap from '../container/Map/GoogleMap';
import ContactFromContainer from '../container/ContactFromContainer/ContactFromContainer';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import data3 from "../data/SEO/seoData";
import {useLocation} from "react-router-dom";

const Contact = () => {
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    return (
        <React.Fragment>
            <SEO title="Contact Tetrahed, Inc. now for your technology and staffing needs" URL="/contact-us"
                 description="Tetrahed, Inc is committed to delivering business success to varied clients by offering cuttin-edge IT services and world-class teams. Explore our offerings now!" />
            <Header />
            <Breadcrumb
                image="/images/bg/Contactus_header_Image.jpg"
                title="Lets Connect and Conquer Together!"
                content="Home"
                contentTwo="Contact Us"
            />
            <ContactInformation />
            <GoogleMap />
            <ContactFromContainer />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Contact;

