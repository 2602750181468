import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import {Link} from "react-router-dom";

const CallToActionThree = ({titleContent}) => {
    return (
        /*<div className="cta-section section section-padding-t90-b10">*/
        <div className="section section-bg-image section-padding-t110-b120 newsletter-section overlay-two" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/newsletter.jpg)`}}>
            <div className="container text-center icon-up-down-animation">
                {/*<SectionTitle
                    headingOption="fz-34"
                    title={titleContent}
                />*/}
                <div className="section-title text-left aos-init aos-animate" data-aos="fade-up" >
                    <h2 className="title fz-34 section-title-Imp" dangerouslySetInnerHTML={{__html: titleContent}}></h2>
                    {/*<p className="sub-title">{subTitle}</p>*/}
                </div>
                {/*title={"Power Your Business with the Best Minds & In-demand Services"}*/}
                <Link className="btn btn-primary btn-hover-secondary customBtn2" to={process.env.PUBLIC_URL + "/contact-us"} data-aos="fade-up" data-aos-delay="300">Contact Us</Link>
            </div>
        </div>
    )
}

export default CallToActionThree;
