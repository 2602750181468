import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
// import WorkContainer from '../container/Industries/WorkContainer';
import ITServiceContainer from '../container/Work/ITServiceContainer';
import CallToAction from '../container/CallToAction/CallToAction';
import AboutFive from '../container/About/AboutFive';
import BrandContainer from '../container/Brand/BrandContainer';
import Faq from '../container/Faq/Faq';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import aboutFiveData from "../data/content/aboutFiveContent";
import CallToActionThree from "../container/CallToAction/CallToActionThree";
import {useLocation} from "react-router-dom";
import data3 from "../data/SEO/seoData";


const ITService = () => {
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} description={seo_data[0].metaDesc} keywords={seo_data.keywords} />
            <Header />
            <Breadcrumb
                image={seo_data[0].bcEleImage}
                title={seo_data[0].bcEleTitle}
                content="Home"
                contentTwo="IT Services"
                pageSpecific={`bg_right_top`}
            />
            <ITServiceContainer
                serviceTitle="As a technology company, we embrace limitless possibilities, mastering a spectrum of tech domains."
            />
            {/*<CallToAction />
            <AboutFive
                mainContent={aboutFiveData.aboutUs.mainContent}
                subTitle={aboutFiveData.aboutUs.subTitle}
                title={aboutFiveData.aboutUs.title}
            />
            <BrandContainer classOption="section-padding-bottom" />
            <Faq />*/}
            <CallToActionThree titleContent="Ignite Your Business Success with Our IT Excellence"/>
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default ITService;