import React, { useState, useEffect } from 'react';
import SEO from '../components/SEO';
import PropTypes from "prop-types";
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import jobsData from "../data/career/jobsData";
import ScrollToTop from '../components/ScrollToTop.jsx';
import JobDetailsContainer from "../container/Career/JobDetailsContainer";
import axios from "axios";

const JobDetail = ({ match: { params: { id } } }) => {
    const jobId = parseInt(id, 10);
    /*const baseUri = "https://tetrahed.s3.us-east-1.amazonaws.com/jobsData.json";
    const fetchDataFromAPI = async () => {
        try {
            const response = await axios.get(baseUri);
            if (response.status === 200) {
                console.log(response)
                setFetchedData(response.data);
            } else {
                throw new Error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
            // setting internal JobsData
            setFetchedData(jobsData);
        }
    };*/
    const [fetchedData, setFetchedData] = useState([]);
    useEffect(() => {
        /*fetchDataFromAPI().then();*/
        setFetchedData(jobsData);
    }, []);

    // Render job details only if fetchedData is available
    const jobData = fetchedData ? fetchedData.find(job => job.id === jobId) : null;

    return (
        <React.Fragment>
            {jobData ? (
                <>
                    <SEO title={`Tetrahed | ${jobData?.Role}`} />
                    <Header />
                    <Breadcrumb
                        image="/images/bg/breadcrumb-bg-two.jpg"
                        title={jobData?.title}
                        content="Home"
                        contentTwo="job detail"
                    />
                    {jobData && <JobDetailsContainer data={jobData} />}
                    <Footer />
                    <ScrollToTop />
                </>
            ) : (
                <div className="loaderMainDiv">
                    <span className="loader"></span>
                </div>
            )}
        </React.Fragment>

    )
}

JobDetail.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ])
        })
    })
}

export default JobDetail;