import React from 'react';
import { useHistory ,useLocation } from 'react-router-dom';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import AboutFour from '../container/About/AboutFour';
import Video from '../container/Video/Video';
import AboutFive from '../container/About/AboutFive';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import data1 from "../data/content/aboutFourContent";
import data2 from "../data/content/aboutFiveContent";
import data3 from "../data/SEO/seoData";
import CallToActionThree from "../container/CallToAction/CallToActionThree";

const PermanentStaffing = () => {
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    console.log(currentPage, currentPage.pathname);
    console.log(seo_data,"seo_data");
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} keywords={seo_data[0].keywords}
                 description={seo_data[0].metaDesc} />
            <Header/>
            <Breadcrumb
                image={seo_data[0].bcEleImage}
                title={seo_data[0].bcEleTitle}
                content="Home"
                contentTwo="Permanent Staffing"
            />
            <AboutFour
                mainContent={data1.permanent.mainContent}
                subTitle={data1.permanent.subTitle}
                title={data1.permanent.title}
                foregroundImg={data1.permanent.foregroundImg} backgroundImg={data1.permanent.backgroundImg}
            />
            <Video content="Empowering the Future with Permanent Staffing Solutions" />
            <AboutFive
                mainContent={data2.permanent.mainContent}
                subTitle={data2.permanent.subTitle}
                title={data2.permanent.title}
                foregroundImg={data2.permanent.foregroundImg} backgroundImg={data2.permanent.backgroundImg}
            />
            {/*<TestimonialContainer classOption="bg-primary-blue"/>*/}
            <CallToActionThree titleContent="Transform Your Business with the Ideal Team"/>
            <Footer/>
            <ScrollToTop/>
        </React.Fragment>
    )
}

export default PermanentStaffing;



