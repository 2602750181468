import PropTypes from "prop-types";
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import BlogClassicData from '../../data/blog/BlogClassic.json';
import BlogClassic from '../../components/Blog/BlogClassic.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
import SidebarTitle from '../../components/Sidebar/SidebarTitle';
import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const BlogClassicContainer = () => {
    const blogsPerPage = 4;
    const maxPageNumbers = 4; // Maximum number of page numbers to show
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = BlogClassicData.slice(indexOfFirstBlog, indexOfLastBlog);

    const paginate = pageNumber => setCurrentPage(pageNumber);
    const goToPrevPage = () => setCurrentPage(currentPage - 1);
    const goToNextPage = () => setCurrentPage(currentPage + 1);

    const totalPageCount = Math.ceil(BlogClassicData.length / blogsPerPage);
    let pageNumbers = [];

    if (totalPageCount <= maxPageNumbers) {
        // Show all page numbers if there are fewer pages than the maximum allowed
        pageNumbers = Array.from({ length: totalPageCount }, (_, i) => i + 1);
    } else {
        // Show a limited number of page numbers with ellipsis
        const leftEllipsis = currentPage > Math.ceil(maxPageNumbers / 2);
        const rightEllipsis = currentPage < totalPageCount - Math.floor(maxPageNumbers / 2);

        if (leftEllipsis && rightEllipsis) {
            // Show both left and right ellipsis
            pageNumbers = [
                1,
                '...',
                ...Array.from({ length: maxPageNumbers - 2 }, (_, i) => i + currentPage - Math.floor(maxPageNumbers / 2) + 1),
                '...',
                totalPageCount
            ];
        } else if (leftEllipsis) {
            // Show left ellipsis
            pageNumbers = [
                1,
                '...',
                ...Array.from({ length: maxPageNumbers - 1 }, (_, i) => i + totalPageCount - maxPageNumbers + 1)
            ];
        } else if (rightEllipsis) {
            // Show right ellipsis
            pageNumbers = [
                ...Array.from({ length: maxPageNumbers - 1 }, (_, i) => i + 1),
                '...',
                totalPageCount
            ];
        } else {
            // Show all page numbers
            pageNumbers = Array.from({ length: totalPageCount }, (_, i) => i + 1);
        }
    }

    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">

                            {currentBlogs.map((single, key) => {
                                return (
                                    <div key={key} className="col mb-6" data-aos="fade-up">
                                        <BlogClassic data={single} key={key} />
                                        {/*<div>{single.body}</div>*/}
                                    </div>
                                );
                            })}

                        </div>

                        <div className="row">
                            <div className="col">

                                <ul className="pagination center">
                                    <li>
                                        <Link onClick={goToPrevPage} className="prev" disabled={currentPage === 1}>
                                            <i className="fal fa-angle-left fa-2x"></i>
                                            {/*<span>Prev</span>*/}
                                        </Link>
                                    </li>
                                    {pageNumbers.map((pageNumber, i) => (
                                        <li key={i}>
                                            {pageNumber === '...' ? (
                                                <span>{pageNumber}</span>
                                            ) : (
                                                <Link className={currentPage === pageNumber ? 'activeBtn' : ''}
                                                      onClick={() => paginate(pageNumber)}>{pageNumber}</Link>
                                            )}
                                        </li>
                                    ))}
                                    <li>
                                        <Link onClick={goToNextPage} className="next" disabled={currentPage === totalPageCount}>
                                            <i className="fal fa-angle-right fa-2x"></i>
                                            {/*<span>Next</span>*/}
                                        </Link>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/*<SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                <SidebarCategories />
                            </SidebarWidget>*/}
                            <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget>
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}

BlogClassicContainer.propTypes = {
    data: PropTypes.object
};

export default BlogClassicContainer;

