import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import IntroSlider from '../container/IntroSlider/IntroSlider';
import HomeAbout from '../components/About/HomeAbout.jsx';
import ServiceIconBox from '../container/service/ServiceIconBox';
import HomeSuccess from '../components/Success/HomeSuccess';
import Portfolio from '../container/Portfolio/Portfolio';
import HomeBlog from '../container/BlogGrid/HomeBlog';
import Newsletter from '../container/Newsletter/Newsletter';
import ContactInformation from '../container/ContactInformation/ContactInformation';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import sectionData from '../data/sectionContent/sectionContent'
import IconBoxData from '../data/iconBox/icon-box2';
import {useLocation} from "react-router-dom";
import data3 from "../data/SEO/seoData";

const LearnMore = () => {
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} description={seo_data[0].metaDesc} />
            <Header />
            <IntroSlider />
            <HomeAbout />
            <ServiceIconBox
                classOption="bg-color-1"
                title={sectionData[1].title}
                subTitle={sectionData[1].subTitle}
                iconBoxData={IconBoxData}
            />
            <HomeSuccess />
            {/*<Portfolio />*/}
            <HomeBlog title="A Comprehensive IT services and Staffing Agency"
                      subTitle="We pride ourselves on our flexibility and ability to adapt to the evolving demands of your business. We don't just offer pre-packaged solutions; we provide precisely what our clients ask for, ensuring a perfect fit for your organizational requirements." />
            <Newsletter />
            <ContactInformation title="Transform Aspirations into Achievements. Act now!" />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default LearnMore;



