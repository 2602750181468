import PropTypes from "prop-types";
import React from 'react';

const SectionTitle = ({ title, subTitle, titleOption, headingOption, isH1Tag, isH3Tag, showWithSpan, classOption }) => {
    let Tag = isH1Tag ? 'h1' : isH3Tag ? 'h3' : 'h2';

    return (
        <>
            {showWithSpan == 'true' ? (
                <div className={`section-title-two ${classOption}`} data-aos="fade-up">
                    <span className="sub-title">{subTitle}</span>
                    <h3 className="title" dangerouslySetInnerHTML={{__html: title}}/>
                </div>
            ) : (
                <div className={`section-title text-center ${titleOption}`} data-aos="fade-up">
                    <Tag className={`title ${headingOption}`} dangerouslySetInnerHTML={{__html: title}}></Tag>
                    <p className="sub-title mw-100">{subTitle}</p>
                </div>
            )}

        </>
    );
}


SectionTitle.propTypes = {
    subTitle: PropTypes.string,
    title: PropTypes.string,
    titleOption: PropTypes.string,
    headingOption: PropTypes.string
};
SectionTitle.defaultProps = {
    titleOption: "text-left",
    headingOption: "title"
};

export default SectionTitle;
