import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import WorkData from '../../data/ITServicesData/ITServices';
import ServiceItems from '../../components/Work/ServiceItems.jsx';

const ITServiceContainer = ({serviceTitle}) => {
    return (
        <div className="section section-padding-t90-b100">
            <div className="container">
                <div className="text-center">
                    <SectionTitle
                        headingOption="title fz-32"
                        title={serviceTitle}
                    />
                </div>

                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 mb-n6">

                    {WorkData && WorkData.map((single, key) => {
                        return(
                            <div key={key} className="col mb-6" data-aos="fade-up" data-aos-delay="300">
                                <ServiceItems classOption="box-border" data={single} key={key} />
                            </div>
                        );
                    })}

                </div>

            </div>
        </div>
    )
}

export default ITServiceContainer;
