import {useEffect, useRef, useState} from "react";
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';
import SectionTitle from "../../components/SectionTitles/SectionTitle";


const AboutSix = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top technology-section-padding-bottom-180">
            <div className="container">

                <div className="row">

                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src="https://tetrahed.s3.us-east-1.amazonaws.com/Service-foreground-2.jpg" alt="Service-foreground-2" />
                                </Tilt>
                            </div>
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>

                                    <img src="https://tetrahed.s3.us-east-1.amazonaws.com/Service-Background-2.jpg" alt="Service-Background-2" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitle showWithSpan="true"
                                subTitle="Innovative &amp; Cutting-Edge Technology"
                                title="Your One-Stop Solution Provider"
                            />

                            <p>Tetrahed, Inc. offers a spectrum of services meticulously crafted to meet your distinct
                                needs. From expert staffing solutions like staff augmentation, and permanent and
                                temporary staffing to IT services encompassing custom software development, identity
                                access management, and more, we aim to simplify your business processes and empower your
                                growth with our flexible and dynamic offerings.</p>

                     
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default AboutSix;
