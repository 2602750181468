import PropTypes from "prop-types";
import React from 'react';

const JobListIcons = ({ data, classOption }) => {
    return (
        <div className={classOption} style={{fontSize: '14px'}}>
            <div className="d-inline-block mr-3">
                <i className="fas fa-map-marker-alt mr-1"></i>
                <span>{data.Location}</span>
            </div>
            <div className="d-inline-block mr-3">
                <i className="fas fa-briefcase mr-1"></i>
                <span>{data.Experience}</span>
            </div>
            <div className="d-inline-block mr-3">
                <i className="fas fa-clock mr-1"></i>
                <span>{data.Duration}</span>
            </div>
        </div>
    )
}

JobListIcons.propTypes = {
    data: PropTypes.object,
    classOption: PropTypes.string
};

JobListIcons.defaultProps = {
    classOption: "col-lg-10",
};

export default JobListIcons;
