import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import AboutFour from '../container/About/AboutFour';
import Video from '../container/Video/Video';
import AboutFive from '../container/About/AboutFive';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import data1 from "../data/content/aboutFourContent";
import data2 from "../data/content/aboutFiveContent";
import CallToActionThree from "../container/CallToAction/CallToActionThree";
import {useLocation} from "react-router-dom";
import data3 from "../data/SEO/seoData";

const ContractStaffing = () => {
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} description={seo_data[0].metaDesc} keywords={seo_data.keywords} />
            <Header/>
            <Breadcrumb
                image={seo_data[0].bcEleImage}
                title={seo_data[0].bcEleTitle}
                content="Home"
                contentTwo="ContractStaffing"
            />
            <AboutFour
                mainContent={data1.contract.mainContent} subTitle={data1.contract.subTitle} title={data1.contract.title}
                foregroundImg={data1.contract.foregroundImg} backgroundImg={data1.contract.backgroundImg}
                mainContent2={data1.contract.mainContent2}
            />
            <Video content="Access Specialized Talents without A Long-Term Commitment" />
            <AboutFive
                mainContent={data2.contract.mainContent} subTitle={data2.contract.subTitle} title={data2.contract.title}
                foregroundImg={data2.contract.foregroundImg} backgroundImg={data2.contract.backgroundImg}
            />
            {/*<TestimonialContainer classOption="bg-primary-blue"/>*/}
            <CallToActionThree titleContent="Flexibility You Need, Talent You Deserve"/>
            <Footer/>
            <ScrollToTop/>
        </React.Fragment>
    )
}

export default ContractStaffing;



