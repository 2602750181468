import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import AboutFour from '../container/About/AboutFour';
import Video from '../container/Video/Video';
import AboutFive from '../container/About/AboutFive';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import data1 from "../data/content/aboutFourContent";
import data2 from "../data/content/aboutFiveContent";
import CallToActionThree from "../container/CallToAction/CallToActionThree";
import {useLocation} from "react-router-dom";
import data3 from "../data/SEO/seoData";

const ContractHire = () => {
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} keywords={seo_data[0].keywords}
                 description={seo_data[0].metaDesc} />
            <Header/>
            <Breadcrumb
                image={seo_data[0].bcEleImage}
                title={seo_data[0].bcEleTitle}
                content="Home"
                contentTwo="Contract to Hire"
            />
            <AboutFour
                mainContent={data1.contractHire.mainContent} backgroundImg={data1.contractHire.backgroundImg}
                subTitle={data1.contractHire.subTitle} foregroundImg={data1.contractHire.foregroundImg}
                title={data1.contractHire.title}
            />
            <Video content="Maximizing Success while Minimizing Risks"/>
            <AboutFive
                mainContent={data2.contractHire.mainContent} backgroundImg={data2.contractHire.backgroundImg}
                subTitle={data2.contractHire.subTitle} foregroundImg={data2.contractHire.foregroundImg}
                title={data2.contractHire.title}
            />
            {/*<TestimonialContainer classOption="bg-primary-blue"/>*/}
            <CallToActionThree titleContent="Reduce Hiring Risks and Embrace Contract-to-Hire Staffing"/>
            <Footer/>
            <ScrollToTop/>
        </React.Fragment>
    )
}

export default ContractHire;



