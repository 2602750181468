import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";
import {Card, CardBody, CardTitle} from "reactstrap";
import JobListIcons from "./JobListIcons";

/*import ru from 'javascript-time-ago/locale/ru'*/


/*TimeAgo.addLocale(ru)*/
import ReactTimeAgo from 'react-time-ago';


const Joblist = ({ data, classOption }) => {
    let getPostedDate =  new Date(data.Posted).toString().split('GMT')[0].trim();
    /*console.log("logs from joblist", new Date('2024-05-29').getTime());*/
    /*console.log("logs from joblist", new Date(data.Posted).getTime());*/
    console.log(data.Posted, getPostedDate, typeof getPostedDate);
    return (
        <Card className="card-hover-bg">
            <CardBody className="pt-2 pb-2">
                <CardTitle tag="h5" className="text-brand-color">
                    <Link to={process.env.PUBLIC_URL + `/jobs/${data.id}`}>
                        {data.Role.length > 45 ? data.shortRoleName : data.Role}
                    </Link>
                </CardTitle>
                <div className="row align-items-center justify-content-around">
                    <JobListIcons classOption="col-lg-8 col-md-8 col-sm-8" data={data} />
                    <div className="col-lg-4 col-md-4 col-sm-4 text-right">
                        <Link className="btn btn-sm btn-outline-secondary customBtn1" to={process.env.PUBLIC_URL + `/jobs/${data.id}`}>View Details</Link>
                    </div>
                </div>
                <div className="d-none d-sm-block">
                    <small className="text-muted">
                        <span className="mr-1">Posted</span>
                        <ReactTimeAgo date={getPostedDate} locale="en-US" timeStyle="round"/>
                    </small>
                </div>
            </CardBody>
        </Card>
    )
}

Joblist.propTypes = {
    data: PropTypes.object,
    classOption: PropTypes.string
};

Joblist.defaultProps = {
    classOption: "icon-box text-center",
};

export default Joblist;
