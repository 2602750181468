import PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";
import Parallax from 'parallax-js';
import jobsData from '../../data/career/jobsData';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import Joblist from "./Joblist";
import {Link} from "react-router-dom";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

const JobslistContainer = ({ classOption }) => {
    TimeAgo.addDefaultLocale(en);
    TimeAgo.addLocale(en);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])

    /*pagination*/
    const blogsPerPage = 10;
    const maxPageNumbers = 4; // Maximum number of page numbers to show
    let pageNumbers = [];
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    /*const filteredJobs = jobsData.filter(jobObj => jobObj.JobStatus !== 'closed');
    const currentJobs = filteredJobs.slice(indexOfFirstBlog, indexOfLastBlog);*/
    const currentJobs = jobsData.slice(indexOfFirstBlog, indexOfLastBlog);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const goToPrevPage = () => setCurrentPage(currentPage - 1);
    const goToNextPage = () => setCurrentPage(currentPage + 1);

    const totalPageCount = Math.ceil(jobsData.length / blogsPerPage);

    if (totalPageCount <= maxPageNumbers) {
        // Show all page numbers if there are fewer pages than the maximum allowed
        pageNumbers = Array.from({ length: totalPageCount }, (_, i) => i + 1);
    } else {
        // Show a limited number of page numbers with ellipsis
        const leftEllipsis = currentPage > Math.ceil(maxPageNumbers / 2);
        const rightEllipsis = currentPage < totalPageCount - Math.floor(maxPageNumbers / 2);

        if (leftEllipsis && rightEllipsis) {
            // Show both left and right ellipsis
            pageNumbers = [
                1,
                '...',
                ...Array.from({ length: maxPageNumbers - 2 }, (_, i) => i + currentPage - Math.floor(maxPageNumbers / 2) + 1),
                '...',
                totalPageCount
            ];
        } else if (leftEllipsis) {
            // Show left ellipsis
            pageNumbers = [
                1,
                '...',
                ...Array.from({ length: maxPageNumbers - 1 }, (_, i) => i + totalPageCount - maxPageNumbers + 1)
            ];
        } else if (rightEllipsis) {
            // Show right ellipsis
            pageNumbers = [
                ...Array.from({ length: maxPageNumbers - 1 }, (_, i) => i + 1),
                '...',
                totalPageCount
            ];
        } else {
            // Show all page numbers
            pageNumbers = Array.from({ length: totalPageCount }, (_, i) => i + 1);
        }
    }


    return (
        <div className={`section section-padding-t90 section-padding-botto pb-15 ${classOption}`}>
            <div className="container">

                <SectionTitle
                    headingOption="fz-32"
                    title="Recent Open Positions"
                    subTitle="Explore new career opportunities with us and join on the path to success"
                />

                <div className="row row-cols-lg-3 row-cols-md-1 row-cols-sm-1 row-cols-1 mb-n6 icon-box-shape-animation">

                    {currentJobs && currentJobs.map((single, key) => {
                        return(
                            <div key={key} className="col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="300">
                                <Joblist classOption="box-border" data={single} key={key} />
                            </div>
                        );
                    })}

                    <div className="shape shape-1" id="scene" ref={sceneEl}>
                        <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/video-shape-1.png"} alt="" /></span>
                    </div>

                </div>

                <div className="row">
                    <div className="col-lg-12 mt-10">

                        <ul className="pagination center">
                            {/*<li>
                                <Link onClick={goToPrevPage} className="prev" disabled={currentPage === 1}>
                                    <i className="fal fa-angle-left fa-2x"></i>
                                </Link>
                            </li>*/}
                            {jobsData.length > 10 && pageNumbers.map((pageNumber, i) => (
                                <li key={i}>
                                    {pageNumber === '...' ? (
                                        <span>{pageNumber}</span>
                                    ) : (
                                        <Link to='#' className={currentPage === pageNumber ? 'activeBtn' : ''}
                                              onClick={() => paginate(pageNumber)}>{pageNumber}</Link>
                                    )}
                                </li>
                            ))}
                            {/*<li>
                                <Link onClick={goToNextPage} className="next" disabled={currentPage === totalPageCount}>
                                    <i className="fal fa-angle-right fa-2x"></i>
                                </Link>
                            </li>*/}
                        </ul>

                    </div>
                </div>

            </div>
        </div>
    )
}


export default JobslistContainer;