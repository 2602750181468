import {useEffect} from "react";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
import LearnMore from './pages/learnMore';
import HomeTwo from './pages/HomeTwo';
import HomeThree from './pages/HomeThree';
import About from './pages/About';
import RecruitAndStaffService from './pages/RecruitmentAndStaffing';
import PermanentStaffing from './pages/PermanentStaffing';
import ContractStaffing from './pages/ContractStaffing';
import ContractHire from './pages/ContractHire';
import EnterpriseApplicationSolution from './pages/EnterpriseApplicationSolution';
import StaffAugmentation from './pages/StaffAugmentation';
import ITService from './pages/ITService';
import CloudService from './pages/Cloud';
import CyberSecurity from './pages/Cybersecurity';
import AIMLService from './pages/AI-ML';
import NetworkServices from './pages/NetworkServices';
import DataAnalytics from './pages/DataAnalytics';
import IdentityAccessManagement from './pages/IdentityAccessManagement';
import BusinessConsulting from './pages/BusinessConsulting';
import Service from './pages/Service';
import Industries from './pages/Industries';
import WorkDetails from './pages/WorkDetails';
import ServiceDetails from './pages/ServiceDetails';
import BlogGrid from './pages/BlogGrid';
import BlogClassic from './pages/BlogClassic';
import BlogDetails from './pages/BlogDetails';
import BlogCategories from './pages/BlogCategories';
import BlogTag from './pages/BlogTag';
import Contact from './pages/Contact';

// Google Analytics
/*import ReactGA from 'react-ga';*/

// CSS File Here
import "aos/dist/aos.css";
import 'react-modal-video/scss/modal-video.scss';
import './assets/scss/style.scss';
import FMCG from "./pages/FMCG";
import AutoMobile from "./pages/AutoMobile";
import BankingAndFinance from "./pages/BankingAndFinance";
import EducationalInstitutions from "./pages/EducationalInstitutions";
import Government from "./pages/Government";
import HealthCare from "./pages/HealthCare";
import Insurance from "./pages/Insurance";
import Logistics from "./pages/Logistics";
import Retail from "./pages/Retail";
import Travel from "./pages/Travel";
import Telecommunications from "./pages/Telecommunications";
import EngineeringAndManufacturing from "./pages/EngineeringAndManufacturing";
import Career from "./pages/Careers";
import JobDetail from "./pages/JobDetail";


function App() {
    // Initialize Google Analytics with your tracking ID
    /*ReactGA.initialize('G-EPWG1ZX2D9');*/
  useEffect(() => {
    AOS.init({
        offset: 80,
        duration: 1000,
        once: true,
        easing: 'ease',
    });
    AOS.refresh();
    
  }, [])
  return (
      <Router>
        <NavScrollTop>
            <Switch>
              <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomeThree}/>
              <Route path={`${process.env.PUBLIC_URL + "/learn-more"}`} exact component={LearnMore}/>
              <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} component={HomeTwo}/>
              <Route path={`${process.env.PUBLIC_URL + "/home-three"}`} component={HomeThree}/>
              <Route path={`${process.env.PUBLIC_URL + "/about"}`} component ={About} />
              <Route path={`${process.env.PUBLIC_URL + "/services/recruitment-staffing"}`} component ={RecruitAndStaffService} />
              <Route path={`${process.env.PUBLIC_URL + "/services/enterprise-solutions"}`} component ={EnterpriseApplicationSolution} />
              <Route path={`${process.env.PUBLIC_URL + "/services/staff-augmentation"}`} component ={StaffAugmentation} />
              <Route path={`${process.env.PUBLIC_URL + "/services/contract-staffing"}`} component ={ContractStaffing} />
              <Route path={`${process.env.PUBLIC_URL + "/services/permanent-staffing"}`} component ={PermanentStaffing} />
              <Route path={`${process.env.PUBLIC_URL + "/services/contract-to-hire"}`} component ={ContractHire} />

              <Route path={`${process.env.PUBLIC_URL + "/it-services/cloud"}`} component ={CloudService} />
              <Route path={`${process.env.PUBLIC_URL + "/it-services/cyber-security"}`} component ={CyberSecurity} />
              <Route path={`${process.env.PUBLIC_URL + "/it-services/ai-ml"}`} component ={AIMLService} />
              <Route path={`${process.env.PUBLIC_URL + "/it-services/network-services"}`} component ={NetworkServices} />
              <Route path={`${process.env.PUBLIC_URL + "/it-services/data-analytics"}`} component ={DataAnalytics} />
              <Route path={`${process.env.PUBLIC_URL + "/it-services/iam"}`} component ={IdentityAccessManagement} />
              <Route path={`${process.env.PUBLIC_URL + "/it-services/business-consulting"}`} component ={BusinessConsulting} />
              <Route path={`${process.env.PUBLIC_URL + "/it-services"}`} component ={ITService} />

              <Route path={`${process.env.PUBLIC_URL + "/services"}`} component ={Service} />
              <Route path={`${process.env.PUBLIC_URL + "/service-detail/:id"}`} component ={ServiceDetails} />
              {/*<Route path={`${process.env.PUBLIC_URL + "/industries/fmcg"}`} component ={FMCG} />
              <Route path={`${process.env.PUBLIC_URL + "/industries/automobile"}`} component ={AutoMobile} />*/}
              <Route path={`${process.env.PUBLIC_URL + "/industries/banking-finance"}`} component ={BankingAndFinance} />
              {/*<Route path={`${process.env.PUBLIC_URL + "/industries/education"}`} component ={EducationalInstitutions} />*/}
              <Route path={`${process.env.PUBLIC_URL + "/industries/government"}`} component ={Government} />
              <Route path={`${process.env.PUBLIC_URL + "/industries/health-care"}`} component ={HealthCare} />
              <Route path={`${process.env.PUBLIC_URL + "/industries/insurance"}`} component ={Insurance} />
              {/*<Route path={`${process.env.PUBLIC_URL + "/industries/logistics"}`} component ={Logistics} />*/}
              <Route path={`${process.env.PUBLIC_URL + "/industries/retail"}`} component ={Retail} />
              <Route path={`${process.env.PUBLIC_URL + "/industries/travel"}`} component ={Travel} />
              <Route path={`${process.env.PUBLIC_URL + "/industries/telecommunication"}`} component ={Telecommunications} />
              <Route path={`${process.env.PUBLIC_URL + "/industries/engineering-manufacturing"}`} component ={EngineeringAndManufacturing} />
              <Route path={`${process.env.PUBLIC_URL + "/industries"}`} component ={Industries} />


              <Route path={`${process.env.PUBLIC_URL + "/careers"}`} component ={Career} />
              <Route path={`${process.env.PUBLIC_URL + "/jobs/:id"}`} component ={JobDetail} />
              <Route path={`${process.env.PUBLIC_URL + "/work"}`} component ={Industries} />
              <Route path={`${process.env.PUBLIC_URL + "/work-details/:id"}`} component ={WorkDetails} />
              <Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} component ={BlogGrid} />
              <Route path={`${process.env.PUBLIC_URL + "/blogs"}`} component ={BlogClassic} />
              <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} component ={BlogTag} />
              <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`}component ={BlogCategories} />
              <Route path={`${process.env.PUBLIC_URL + "/blog/:id"}`}component ={BlogDetails} />
              <Route path={`${process.env.PUBLIC_URL + "/contact-us"}`} component ={Contact} />

              {/* <Route component ={NotFound} /> */}
            </Switch>
          </NavScrollTop>
      </Router>
  );
}

export default App;
