import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import IntroThree from '../container/IntroSlider/IntroThree';
import HomeAboutThree from '../container/About/HomeAboutThree';
import Funfact from '../container/Funfact/Funfact';
import ServiceIconBox from '../container/service/ServiceIconBox';
import Faq from '../container/Faq/Faq';
import CallToAction from '../container/CallToAction/CallToAction';
import ContactInformation from '../container/ContactInformation/ContactInformation';
import BrandContainer from '../container/Brand/BrandContainer';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import sectionData from '../data/sectionContent/sectionContent'
import IconBoxData from '../data/iconBox/icon-box.json';
import {useLocation} from "react-router-dom";
import data3 from "../data/SEO/seoData";


const HomeThree = () => {
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} description={seo_data[0].metaDesc} />
            <Header />
            <IntroThree />
            <HomeAboutThree />
            <Funfact classOption="section-padding bg-primary-blue" />
            <ServiceIconBox
                title={sectionData[0].title}
                subTitle={sectionData[0].subTitle} iconBoxData={IconBoxData} />
            <Faq />
           {/* <PortfolioTwo />
            <Team />  
    <TestimonialContainer /> */}
            <CallToAction sectionTitleContent="Ready to start your project? The contact information collected through this form will only be used to send a response to your inquiry." />
          {/*  <HomeBlog /> */}
            <ContactInformation classOption="bg-primary-blue"
                                title="Kickstart the Journey Towards Your Business Goals. Call Us Now!" />
            <BrandContainer classOption="section-padding" />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default HomeThree;



