import React, {useState} from 'react';
import {useForm} from "react-hook-form";

const CustomNewsLetter = (props) => {
    const { register, errors } = useForm({
        mode: "onBlur"
    });
    const [formData, setFormData] = useState({
        emailId: ''
    });
    let email;
    const [errorMessage, setErrorMessage] = useState('');
    const submit = (e) => {
        e.preventDefault();
        console.log("logs from contact us form", e)
        // Check if emailId is empty
        if (!formData.emailId.trim()) {
            setErrorMessage('Email is required');
            return;
        } else {
            setErrorMessage('');
        }
        const config = {
            SecureToken : "118c3d49-ce2a-4e28-998f-cb1a17538acd",
            To : 'commons@tetrahed.com',
            From : "contactus@tetrahed.com",
            Subject : "New user subscribed for Newsletter",
            Body : `Email ID for the user: ${formData.emailId}`
        }
        try {
            window.Email.send(config).then(
                (message) => {
                    if(message == 'OK'){
                        alert("you have successfully subscribed to our newsletter");
                    }
                    else{
                        alert("oops something went wrong, please try after sometime");
                    }
                }

            );
            // Reset the form after successful submission
            setFormData({emailId: '' });
        } catch (error) {
            alert('Oops something went wrong');
            console.error('Error sending email:', error);
        }

    };
    // Change Handaler
    const inputChangedHandler = (e) => {
        console.log(e.target.value);
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // Check if emailId is empty
        if (!formData.emailId.trim()) {
            setErrorMessage('Email is required');
            return;
        } else {
            setErrorMessage('');
        }
    }

    return (
        <div className="newsletter-form" data-aos="fade-up" data-aos-delay="300">
            <form>
                <input id="mc-form-email" className="email" type="email" onChange={(e) => inputChangedHandler(e)}
                       placeholder="Enter your email address" name="emailId"
                       ref={register({
                           required: 'Email is required',
                           pattern: {
                               value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                               message: "invalid email address"
                           }
                       })}
                />
                <button className="btn btn-brand-color btn-hover-secondary" onClick={submit}>Subscribe</button>
            </form>
            {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    )
}



export default CustomNewsLetter;