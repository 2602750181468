import PropTypes from "prop-types";
import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import JobApplicationForm from "../../components/Career/JobApplicationForm";
import JobListIcons from "../../components/Career/JobListIcons";


const JobDetailsContainer = ({ data }) => {
    // const cate = data.categories.map((value, i) => {
    //     return(
    //         <span className="d-inline" key={i}>{value}{i !== data.categories.length - 1 && " , "}</span>
    //     )
    // })
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    console.log("jobdetails container loaded with data", data);
    const closeBtn = (
        <div className="close" onClick={toggle} type="button">
            <i className="far fa-times" style={{fontSize: '1.4em'}}></i>
        </div>
    );
    return (
        <div className="section section-padding pt-5 pb-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="mainHeaderDiv col-lg-10 col-md-8">
                        {/* job box 2 start */}
                        <div className="row">
                            <div className="job-box-2 row align-items-center pb-3">
                                <div className="description col-lg-10 ml-0">
                                    <h2 className="title text-brand-color">{data.Role}</h2>
                                    <div className="row">
                                        <JobListIcons classOption="col-lg-10" data={data} />
                                    </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                    <Link to="#" className="btn btn-primary btn-hover-secondary mt-3 customBtn2 w-100"
                                          onClick={toggle} >Apply Now</Link>
                                </div>
                            </div>
                            <hr className="hr-boder mb-5" />
                            {/* job description start */}
                            {data.JobDescription &&
                                <div className="job-description mb-5" data-aos="fade-up">
                                    <h5 className="heading-2">Job Description</h5>
                                    <p style={{fontSize: '14px'}}>{data.JobDescription}</p>
                                </div>
                            }
                            {/* job description points start */}
                            {data.JobDescPoints &&
                            <div className="job-description mb-5" data-aos="fade-up">
                                <h5 className="heading-2">Job Description</h5>
                                <ul>
                                    {data.JobDescPoints.map((value, i) => {
                                        return(
                                            <li style={{fontSize: '14px'}} key={i} className="content mb-2" data-aos="fade-up" dangerouslySetInnerHTML={{__html: value}} />
                                        )
                                    })}
                                </ul>
                            </div>
                            }
                            {/* Education + experience start*/}
                            {data.Skills &&
                                <div className="education-experience amenities mb-5">
                                    <h5 className="heading-2 mb-5" data-aos="fade-up">Skill & Experience</h5>

                                    <ul>
                                        {data.Skills.map((value, i) => {
                                            return(
                                                <li style={{fontSize: '14px'}} key={i} className="content mb-2" data-aos="fade-up" dangerouslySetInnerHTML={{__html: value}} />
                                            )
                                        })}
                                    </ul>
                                </div>
                            }
                            {/* Skills rendered in minifined format */}
                            {data.minifiedSkillsContent &&
                                <div className="education-experience amenities mb-5">
                                    <h5 className="heading-2 mb-5" data-aos="fade-up">Skill & Experience</h5>
                                    <div className="desc" style={{fontSize: '14px', lineHeight: '32px'}} data-aos="fade-up"
                                         dangerouslySetInnerHTML={{__html: data.minifiedSkillsContent}} />
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-12 d-sm-block d-md-none text-center">
                        <Link to="#" className="btn btn-primary btn-hover-secondary mt-3 customBtn2 w-100"
                              onClick={toggle} >Apply Now</Link>
                    </div>

                    {/* Job apply Modal */}
                    <Modal isOpen={modal} toggle={toggle} size="lg">
                        <ModalHeader toggle={toggle} close={closeBtn}>Apply for <span className="text-brand-color">{data.Role}</span> Job</ModalHeader>
                        <ModalBody>
                            <JobApplicationForm data={data} />
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

JobDetailsContainer.propTypes = {
    data: PropTypes.object
};

export default JobDetailsContainer;
