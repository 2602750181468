import React from 'react';
import YoutubeBackground from 'react-youtube-background'
import {Link} from "react-router-dom";

const IntroTwo = () => {
    return (
            <YoutubeBackground
                videoId="eS9Qm4AOOBY"
                overlay="rgba(0,0,0,.4)"
                className="intro-section section bg-video"
            >
                <div className="container">
                    <div className="row row-cols-lg-1 row-cols-1">

                        <div className="col align-self-center">
                            <div className="intro-content-two text-center mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                <h2 className="title">Provides best solutions to build your Organization </h2>
                                <div className="desc">
                                    <p>We are an agency located in Austin. We use Innovative & Cutting Edge Technologies to facilitate your business growth..</p>
                                </div>
                                <Link to={process.env.PUBLIC_URL + "/"} className="btn btn-primary btn-hover-secondary customBtn2">Get Started</Link>
                                <Link to={process.env.PUBLIC_URL + "/"} className="btn btn-outline-white btn-hover-primary customBtn1"> Learn More </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </YoutubeBackground>
    )
}


export default IntroTwo;
