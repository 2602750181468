import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import AboutFour from '../container/About/AboutFour';
import Video from '../container/Video/Video';
import AboutFive from '../container/About/AboutFive';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import data1 from '../data/content/aboutFourContent';
import data2 from '../data/content/aboutFiveContent';
import CallToActionThree from "../container/CallToAction/CallToActionThree";
import {useLocation} from "react-router-dom";
import data3 from "../data/SEO/seoData";

const BusinessConsulting = () => {
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} description={seo_data[0].metaDesc} />
            <Header />
            <Breadcrumb
                image={seo_data[0].bcEleImage}
                title={seo_data[0].bcEleTitle}
                content="Home"
                contentTwo="BusinessConsulting"
            />
            <AboutFour
                mainContent={data1.business.mainContent}
                subTitle={data1.business.subTitle}
                title={data1.business.title}
                backgroundImg={data1.business.backgroundImg} foregroundImg={data1.business.foregroundImg}
            />
            <Video content="Devising Strategies For Business Transformation and Accelerated Business Growth" />
            <AboutFive
                mainContent={data2.business.mainContent}
                subTitle={data2.business.subTitle}
                title={data2.business.title}
                backgroundImg={data2.business.backgroundImg} foregroundImg={data2.business.foregroundImg}
            />
            {/*<TestimonialContainer classOption="bg-primary-blue" />*/}
            <CallToActionThree titleContent="Ready to Achieve More?" />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default BusinessConsulting;



