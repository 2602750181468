import React from 'react';
import {NavLink} from 'react-router-dom';
import {
    getClosest,
    getSiblings,
    slideToggle,
    slideUp,
} from "../../../utils";

const MobileNavMenu = () => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-toggle") ||
            target.classList.contains("menu-toggle")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    return (
        <nav className="site-mobile-menu">
            <ul>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">Home</span></NavLink>
                    {/*<span className="menu-toggle" onClick={onClickHandler}><i className="icon far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/home-one"}><span className="menu-text">Home One</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/home-two"}><span className="menu-text">Home Two</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/home-three"}><span className="menu-text">Home Three</span></NavLink></li>
                    </ul>*/}
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/about"}><span className="menu-text">About Us</span></NavLink>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/services"}><span
                        className="menu-text">Services</span></NavLink>
                    <span className="menu-toggle" onClick={onClickHandler}><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li className="has-children">
                            <NavLink to={process.env.PUBLIC_URL + "/services/recruitment-staffing"}><span className="menu-text">Recruitment & Staffing</span></NavLink>
                            <span className="menu-toggle" onClick={onClickHandler}><i className="far fa-angle-down"></i></span>
                            <ul className="sub-menu">
                                <li><NavLink to={process.env.PUBLIC_URL + "/services/staff-augmentation"}><span className="menu-text">Staff Augmentation</span></NavLink></li>
                                <li><NavLink to={process.env.PUBLIC_URL + `/services/contract-staffing`}><span className="menu-text">Contract Staffing</span></NavLink></li>
                                <li><NavLink to={process.env.PUBLIC_URL + "/services/permanent-staffing"}><span className="menu-text">Permanent Staffing</span></NavLink></li>
                                <li><NavLink to={process.env.PUBLIC_URL + `/services/staffing/contract-to-hire`}><span className="menu-text">Contract-to-hire staffing</span></NavLink></li>
                            </ul>
                        </li>
                        <li className="has-children">
                            <NavLink to={process.env.PUBLIC_URL + `/it-services`}><span className="menu-text">IT Services</span></NavLink>
                            <span className="menu-toggle" onClick={onClickHandler}><i className="far fa-angle-down"></i></span>
                            <ul className="sub-menu">
                                <li><NavLink to={process.env.PUBLIC_URL + "/it-services/cloud"}><span className="menu-text">Cloud</span></NavLink></li>
                                <li><NavLink to={process.env.PUBLIC_URL + `/it-services/cyber-security`}><span className="menu-text">Cybersecurity</span></NavLink></li>
                                <li><NavLink to={process.env.PUBLIC_URL + "/it-services/ai-ml"}><span className="menu-text">AI / ML</span></NavLink></li>
                                <li><NavLink to={process.env.PUBLIC_URL + `/it-services/network-services`}><span className="menu-text">Network Services</span></NavLink></li>
                                <li><NavLink to={process.env.PUBLIC_URL + "/it-services/data-analytics"}><span className="menu-text">Data Analytics</span></NavLink></li>
                                {/*<li><NavLink to={process.env.PUBLIC_URL + `/services/it-service/cloud`}><span className="menu-text">Enterprise Solutions</span></NavLink></li>*/}
                                <li><NavLink to={process.env.PUBLIC_URL + "/it-services/iam"}><span className="menu-text">Identity Access Management</span></NavLink></li>
                                <li><NavLink to={process.env.PUBLIC_URL + `/it-services/business-consulting`}><span className="menu-text">Business Consulting</span></NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/services/enterprise-solutions`}><span className="menu-text">Enterprise Solutions</span></NavLink></li>
                    </ul>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + `/industries`}><span className="menu-text">Industries</span></NavLink>
                    <span className="menu-toggle" onClick={onClickHandler}><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + `/industries/travel`}><span className="menu-text">Travel</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/industries/banking-finance"}><span className="menu-text"> Banking and Finance</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/industries/health-care"}><span className="menu-text">Healthcare</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/industries/insurance"}><span className="menu-text">Insurance</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/industries/retail"}><span className="menu-text">Retail</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/industries/government`}><span className="menu-text">Government </span></NavLink></li>
                        {/*<li><NavLink to={process.env.PUBLIC_URL + `/industries/education`}><span className="menu-text">Educational Institutions</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/industries/fmcg`}><span className="menu-text">FMCG</span></NavLink></li>*/}
                        <li><NavLink to={process.env.PUBLIC_URL + `/industries/engineering-manufacturing`}><span className="menu-text">Engineering and Manufacturing</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/industries/telecommunication`}><span className="menu-text">Telecommunications</span></NavLink></li>
                        {/*<li><NavLink to={process.env.PUBLIC_URL + "/industries/automobile"}><span className="menu-text">Automobile</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/industries/logistics`}><span className="menu-text">Logistics</span></NavLink></li>*/}
                    </ul>
                </li>
                <li><NavLink to={process.env.PUBLIC_URL + `/careers`}><span className="menu-text">Careers</span></NavLink></li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/blogs"}><span
                        className="menu-text">Blog</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                </li>
                {/*<li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/blog-grid"}><span className="menu-text">Blog</span></NavLink>
                    <span className="menu-toggle" onClick={onClickHandler}><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/blog-grid"}><span className="menu-text">Blog Grid</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/blog-classic"}><span className="menu-text">Blog Classic</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/blog-details/1`}><span className="menu-text">Blog Details</span></NavLink></li>
                    </ul>
                </li>*/}
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/contact-us"}><span className="menu-text">Contact Us</span></NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default MobileNavMenu;
