import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import AboutFour from '../container/About/AboutFour';
import Video from '../container/Video/Video';
import AboutFive from '../container/About/AboutFive';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import data1 from '../data/content/aboutFourContent';
import data2 from '../data/content/aboutFiveContent';
import {useLocation} from "react-router-dom";
import data3 from "../data/SEO/seoData";

const AboutUs = () => {
        const currentPage = useLocation();
        let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} description={seo_data[0].metaDesc} />
            <Header />
            <Breadcrumb
                image={seo_data[0].bcEleImage}
                title={seo_data[0].bcEleTitle}
                content="Home"
                contentTwo="About Us"
            />
            <AboutFour
                mainContent={data1.aboutUs.mainContent} mainContent2={data1.aboutUs.mainContent2}
                subTitle={data1.aboutUs.subTitle} backgroundImg={data1.aboutUs.backgroundImg}
                foregroundImg={data1.aboutUs.foregroundImg} title={data1.aboutUs.title}
            />
            {/*<Video content="Fueling Business Prosperity with Quality IT and Staffing" />*/}`
            <Video content="Scale Up Your Business with Quality IT Services and Staffing Solutions" />
            <AboutFive
                mainContent={data2.aboutUs.mainContent} subTitle={data2.aboutUs.subTitle}
                title={data2.aboutUs.title} backgroundImg={data2.aboutUs.backgroundImg}
                foregroundImg={data2.aboutUs.foregroundImg}
            />
            <TestimonialContainer classOption="bg-primary-blue" />
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default AboutUs;



