import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import AboutFour from '../container/About/AboutFour';
import Video from '../container/Video/Video';
import AboutFive from '../container/About/AboutFive';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import data1 from "../data/content/aboutFourContent";
import data2 from "../data/content/aboutFiveContent";
import CallToActionThree from "../container/CallToAction/CallToActionThree";
import AboutPageWithRadioBtns from "../container/About/AboutPageWithRadioBtns";
import {useLocation} from "react-router-dom";
import data3 from "../data/SEO/seoData";

const EnterpriseApplicationSolution = () => {
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    console.log(currentPage, currentPage.pathname);
    console.log(seo_data,"seo_data");
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} description={seo_data[0].metaDesc} />
            <Header/>
            <Breadcrumb
                image={seo_data[0].bcEleImage}
                title={seo_data[0].bcEleTitle}
                content="Home"
                contentTwo="Enterprise Application Solution"
            />
            <AboutFour
                mainContent={data1.enterpriseSolutions.mainContent}
                subTitle={data1.enterpriseSolutions.subTitle}
                title={data1.enterpriseSolutions.title}
                foregroundImg={data1.enterpriseSolutions.foregroundImg} backgroundImg={data1.enterpriseSolutions.backgroundImg}
            />
            <Video content="Achieve More with Streamlined Operations" />
            {/*<AboutFive
                mainContent={data2.enterpriseSolutions.mainContent}
                subTitle={data2.enterpriseSolutions.subTitle}
                title={data2.enterpriseSolutions.title}
            />*/}
            <AboutPageWithRadioBtns
                title="Our Capabilities" foregroundImg="https://tetrahed.s3.us-east-1.amazonaws.com/Enterprise-solutions-FG-2.jpg"
                subTitle="Innovative Enterprise Solutions" backgroundImg="https://tetrahed.s3.us-east-1.amazonaws.com/Enterprise-solutions-BG-2.jpg" />
            {/*<TestimonialContainer classOption="bg-primary-blue"/>*/}
            <CallToActionThree titleContent="Optimize Your Business Operations with Tetrahed’s Enterprise Solutions" />
            <Footer/>
            <ScrollToTop/>
        </React.Fragment>
    )
}

export default EnterpriseApplicationSolution;



