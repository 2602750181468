import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";

const ServiceItems = ({ data }) => {
    return (
        <div className="work">
            <div className="thumbnail">
                <Link className="image" to={process.env.PUBLIC_URL + `${data.url}`}><img src={`${data.image}`} alt="staffing" /></Link>
            </div>
            <div className="info">
                <h3 className="title"><Link to={process.env.PUBLIC_URL + `${data.url}`}>{data.title}</Link></h3>
                <p className="desc">{data.excerpt}</p>
                <Link to={process.env.PUBLIC_URL + `${data.url}`}>More details</Link>
            </div>
        </div>
    )
}

ServiceItems.propTypes = {
    data: PropTypes.object
};

export default ServiceItems;
