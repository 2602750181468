import React from 'react';
import Typed from 'react-typed';
import {Link} from "react-router-dom";


const IntroThree = () => {
    return (
        <div className="intro-section section overlay" style={{backgroundImage: `url(/images/banner/Home-page-Image.jpg)`}}>

            <div className="container">
                <div className="row row-cols-lg-1 row-cols-1">

                    <div className="col align-self-center">
                        <div className="intro-content-two headline-active text-center mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                            <h2 className="title ah-headline">Building Organizations with{" "} 
                            <Typed
                                strings={[
                                    ' Innovative ',
                                    ' Intelligent ',
                                    ' Cost-efficient ']}
                                    typeSpeed={200}
                                    backSpeed={110}
                                    loop  /> IT Solutions</h2>
                            <div className="desc">
                                <p>Tetrahed, Inc. empowers global businesses to thrive by delivering innovative IT services and strategic staffing solutions, helping them navigate the challenges of today and tomorrow.</p>
                            </div>
                            {/*<Link to={process.env.PUBLIC_URL + "/"} className="btn btn-primary btn-hover-secondary">Get Started</Link>*/}
                            <Link to={process.env.PUBLIC_URL + "/learn-more"} className="btn btn-outline-white btn-hover-primary customBtn1"> Learn More </Link>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default IntroThree
