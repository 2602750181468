import React from 'react';
import IconBox from '../../components/IconBox/IconBox.jsx';

const IconBoxContainer = ({data}) => {
    return (
        <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
            {data && data.slice(0, 3).map((single, key) => {
                return(
                    <div key={key} className="col mb-6" data-aos="fade-up">
                        <IconBox classOption="box-border" data={single} key={key} />
                    </div>
                );
            })}
        </div>
    )
}

export default IconBoxContainer;
